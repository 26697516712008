import { gql } from '@apollo/client';

const TOURNAMENT_LIST_DATA = gql`
	fragment tournamentListData on statTournament {
		id
		name
		url
		currentSeason {
			id
			rounds {
				includeStandings
			}
		}
	}
`;

const STAT_WIDGET_TOURNAMENT_DATA = gql`
	fragment statWidgetTournamentData on statSeason {
		id
		tournament {
			id
			name
			url
		}
		standingsBracketsStages {
			... on statTRound {
				includeStandings
				name
				teamStanding {
					total {
						groupName
						team {
							id
							name
							url
							logo {
								webp(quality: 100, width: "60", height: "60")
							}
						}
						rank
						points
						played
						current_outcome
					}
				}
			}
		}
	}
`;

const STAT_WIDGET_PERSON_DATA = gql`
	fragment statWidgetPersonData on statSeason {
		rankingPlayerStat(
			input: {
				attribute: [
					TOTAL_GOALS
					TOTAL_ASSISTS
					TOTAL_YELLOW_CARD
					TOTAL_RED_CARD
				]
				limit: $limit
			}
		) {
			attribute
			items {
				player {
					id
					firstName
					lastName
					url
					avatar {
						webp(quality: 100, width: "40", height: "40")
					}
				}
				team {
					id
					name
					url
				}
				value
			}
		}
	}
`;
export default {
	getStatisticWidgetTopTournaments: gql`
		${TOURNAMENT_LIST_DATA}
		query getStatisticWidgetTopTournaments {
			teaserQueries {
				topTournaments {
					...tournamentListData
				}
			}
		}
	`,
	getTeamTournaments: gql`
		${TOURNAMENT_LIST_DATA}
		${STAT_WIDGET_TOURNAMENT_DATA}
		${STAT_WIDGET_PERSON_DATA}
		query getTeamTournaments(
			$teamId: ID!
			$seasonId: [String!]!
			$limit: Int = 5
		) {
			stat {
				football {
					stat_team(id: $teamId) {
						currentTournaments {
							...tournamentListData
						}
					}
					stat_season(id: $seasonId) {
						...statWidgetTournamentData
						...statWidgetPersonData
					}
				}
			}
		}
	`,
	getSeasonForWidget: gql`
		${STAT_WIDGET_TOURNAMENT_DATA}
		${STAT_WIDGET_PERSON_DATA}
		query getSeasonForWidget($id: [String!]!, $limit: Int!) {
			stat {
				football {
					stat_season(id: $id) {
						...statWidgetTournamentData
						...statWidgetPersonData
					}
				}
			}
		}
	`,
};
