import { ApolloQueryResult } from '@apollo/client';
import {
	StatQuery,
	StatSeason,
	StatTournament,
	TeaserQueries,
} from '@ui-kit/main-api-types';

import { changeGateway, client } from '@apollo-client-v2';
import { LANG } from '@utils/types';

import queries from './queries';

export const getStatisticWidgetTopTournaments = async (
	locale: LANG,
): Promise<StatTournament[]> => {
	const tournamentsData: ApolloQueryResult<{ teaserQueries: TeaserQueries }> =
		await client.query({
			query: queries.getStatisticWidgetTopTournaments,
			context: {
				headers: {
					'X-Language': locale.toLowerCase(),
				},
			},
			errorPolicy: 'all',
		});

	return tournamentsData?.data?.teaserQueries?.topTournaments;
};

export const getTeamTournamentsAndInitialSeason = async (
	teamId: string,
	seasonId: string[],
	limit: number,
	locale: LANG,
): Promise<{ tournaments: StatTournament[]; season: StatSeason }> => {
	const clientV2 = changeGateway({
		lang: locale.toLowerCase(),
		appname: '',
	});
	const tournamentsData: ApolloQueryResult<{ stat: StatQuery }> =
		await clientV2.query({
			query: queries.getTeamTournaments,
			variables: {
				teamId,
				seasonId,
				limit,
			},
			context: {
				headers: {
					'X-Language': locale.toLowerCase(),
				},
			},
			errorPolicy: 'all',
		});

	return {
		tournaments:
			tournamentsData?.data?.stat?.football?.stat_team?.currentTournaments,
		season: tournamentsData?.data?.stat?.football?.stat_season?.[0],
	};
};

export const getSeasonForWidget = async (
	id: string[],
	limit: number,
	locale: LANG,
): Promise<StatSeason> => {
	if (id?.includes(null)) {
		return null;
	}

	const tournamentsData: ApolloQueryResult<{ stat: StatQuery }> =
		await client.query({
			query: queries.getSeasonForWidget,
			variables: {
				id,
				limit,
			},
			context: {
				headers: {
					'X-Language': locale.toLowerCase(),
				},
			},
			errorPolicy: 'all',
		});

	return tournamentsData?.data?.stat?.football?.stat_season?.[0];
};
